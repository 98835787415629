import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef,
} from '@angular/core';
import { HttpDataState } from '@core/types/http-data-state';
import { CampaignModel } from '@core/models/campaign.model';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export type Value = CampaignModel | null;

@Component({
  selector: 'zero-campaign-control[campaigns]',
  templateUrl: './campaign-control.component.html',
  styleUrls: ['./campaign-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CampaignControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CampaignControlComponent),
      multi: true,
    },
  ],
})
export class CampaignControlComponent
  implements ControlValueAccessor, Validator
{
  @Input() public campaigns!: HttpDataState<CampaignModel[]>;
  @Input() public placeholder = 'Select a campaign';

  public readonly control = new FormControl<CampaignModel | null>(null, [
    Validators.required,
  ]);
  private readonly value$ = this.control.valueChanges.pipe(
    takeUntilDestroyed(),
  );

  private _onChange?: (value: Value) => void;
  private _onTouched?: () => void;

  constructor() {
    this.value$.subscribe(() => {
      this._onTouched?.();
      this._onChange?.(this.control.getRawValue());
    });
  }

  public formatCampaignOption(campaign: CampaignModel): string {
    const startDate = new Date(campaign.start_date).toLocaleDateString();
    const endDate = new Date(campaign.end_date).toLocaleDateString();
    return `${campaign.name} (${startDate} - ${endDate})`;
  }

  public registerOnChange(callback: (value: Value) => void): void {
    this._onChange = callback;
  }

  public registerOnTouched(callback: () => void): void {
    this._onTouched = callback;
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  public validate(): ValidationErrors | null {
    return this.control.valid ? null : { invalid: true };
  }

  public writeValue(value: Value): void {
    value !== null
      ? this.control.patchValue(value, { emitEvent: false })
      : this.control.reset(null, { emitEvent: false });
  }
}
