import { ErrorHandler, Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import {
  ApplicationInsights,
  ITelemetryPlugin,
  ICustomProperties,
  IAutoExceptionTelemetry,
  IDependencyTelemetry,
} from '@microsoft/applicationinsights-web';

@Injectable({ providedIn: 'root' })
export class ApplicationInsightsService {
  private readonly router = inject(Router);
  private readonly config = inject(RUNTIME_CONFIGURATION).appInsights;
  private readonly angularPlugin = new AngularPlugin();
  public readonly instance?: ApplicationInsights;

  constructor() {
    if (this.config.instrumentationKey) {
      this.instance = new ApplicationInsights({
        config: {
          ...this.config,
          enableCorsCorrelation: true,
          enableAutoRouteTracking: true,
          autoTrackPageVisitTime: true,
          extensions: [this.angularPlugin as unknown as ITelemetryPlugin],
          extensionConfig: {
            [this.angularPlugin.identifier]: {
              router: this.router,
              errorServices: [new ErrorHandler()],
            },
          },
        },
      });
      this.instance.loadAppInsights();
      this.instance.addTelemetryInitializer((envelope) => {
        if (envelope.tags) {
          envelope.tags['ai.cloud.role'] = 'zero-frontend';
        }
      });
    }
  }

  public setAuthenticatedUserContext(accountId: string) {
    this.instance?.setAuthenticatedUserContext(accountId);
  }

  public clearAuthenticatedUserContext() {
    this.instance?.clearAuthenticatedUserContext();
  }

  public trackPageView(name?: string, uri?: string) {
    this.instance?.trackPageView({ name, uri });
  }

  public startTrackPage(name?: string) {
    this.instance?.startTrackPage(name);
  }

  public stopTrackPage(
    name?: string,
    url?: string,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number },
  ) {
    this.instance?.stopTrackPage(name, url, properties, measurements);
  }

  public trackEvent(name: string, properties?: { [key: string]: any }) {
    this.instance?.trackEvent({ name: name }, properties);
  }

  public trackMetric(
    name: string,
    average: number,
    properties?: ICustomProperties,
  ) {
    this.instance?.trackMetric({ name: name, average: average }, properties);
  }

  public trackException(
    exception?: Error | IAutoExceptionTelemetry,
    severityLevel?: number,
  ) {
    this.instance?.trackException({ exception, severityLevel });
  }

  public trackTrace(message: string, properties?: ICustomProperties) {
    this.instance?.trackTrace({ message: message }, properties);
  }

  public trackDependencyData(dependency: IDependencyTelemetry) {
    this.instance?.trackDependencyData(dependency);
  }

  public flush() {
    this.instance?.flush();
  }
}
