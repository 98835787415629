import { Injectable, inject } from '@angular/core';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import {
  MgtPerson,
  MgtPersonCard,
  Providers,
  SimpleProvider,
  ProviderState,
} from '@microsoft/mgt';
import { GraphService } from '@core/services/graph.service';
import { PopupRequest, EventType } from '@azure/msal-browser';
import { User } from '@microsoft/microsoft-graph-types';
import { ApplicationInsightsService } from '@core/services/application-insights.service';
import { ComponentStore } from '@ngrx/component-store';
import {
  map,
  firstValueFrom,
  tap,
  switchMap,
  takeUntil,
  filter,
  EMPTY,
} from 'rxjs';

interface AuthState {
  activeAccount: AccountInfo | null;
  user: User | null;
}

const INITTAL_STATE: AuthState = { activeAccount: null, user: null };

@Injectable({ providedIn: 'root' })
export class AuthStore extends ComponentStore<AuthState> {
  private readonly appInsightsService = inject(ApplicationInsightsService);
  private readonly msalService = inject(MsalService);
  private readonly graphService = inject(GraphService);
  private readonly msal = this.msalService.instance;
  private readonly config = inject<PopupRequest>(MSAL_GUARD_CONFIG);
  private readonly msalSubject$ = inject(MsalBroadcastService).msalSubject$;
  private readonly accountUpdated$ = this.msalSubject$.pipe(
    takeUntil(this.destroy$),
    filter(
      (msg) =>
        msg.eventType === EventType.ACCOUNT_ADDED ||
        msg.eventType === EventType.ACCOUNT_REMOVED ||
        msg.eventType === EventType.LOGIN_SUCCESS ||
        msg.eventType === EventType.LOGOUT_SUCCESS,
    ),
  );

  public readonly user$ = this.select(({ user }) => user);
  public readonly activeAccount$ = this.select(
    ({ activeAccount }) => activeAccount,
  );
  public readonly authenticated$ = this.activeAccount$.pipe(map(Boolean));

  constructor() {
    super(INITTAL_STATE);
    this.msalService
      .initialize()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.msalService.instance.enableAccountStorageEvents();
        MgtPerson.config.useContactApis = false;
        MgtPersonCard.config.useContactApis = false;
        MgtPersonCard.config.sections.profile = false;
        MgtPersonCard.config.sections.organization = false;
        MgtPersonCard.config.sections.files = false;
        MgtPersonCard.config.sections.mailMessages = false;
        Providers.globalProvider = new SimpleProvider(
          async (scopes) =>
            await firstValueFrom(
              this.msalService
                .acquireTokenSilent({ scopes })
                .pipe(map((result) => result.accessToken)),
            ),
          async () =>
            await firstValueFrom(
              this.msalService
                .loginPopup(this.config)
                .pipe(switchMap(() => EMPTY)),
            ),
          async () =>
            await firstValueFrom(
              this.msalService.logoutPopup().pipe(
                tap(() => this.updateAuthenticatedState()),
                switchMap(() => EMPTY),
              ),
            ),
        );
        this.accountUpdated$
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => this.updateAuthenticatedState());
        this.updateAuthenticatedState();
      });
  }

  public login(): void {
    this.msalService
      .loginPopup(this.config)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public updateAuthenticatedState(): void {
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts.length > 0) {
      const account = accounts[0];
      Providers.globalProvider.setState(ProviderState.SignedIn);
      this.msal.setActiveAccount(account);
      this.appInsightsService.setAuthenticatedUserContext(account.username);
      this.patchState({ activeAccount: account });
      this.graphService
        .getCurrentUser()
        .pipe(takeUntil(this.destroy$))
        .subscribe((user) => {
          this.patchState({ user });
        });
    } else {
      Providers.globalProvider.setState(ProviderState.SignedOut);
      this.msal.setActiveAccount(null);
      this.appInsightsService.clearAuthenticatedUserContext();
      this.patchState({ activeAccount: null, user: null });
    }
  }
}
